import React from 'react';
import './App.css';

const App = () => {
    return (
        <div className="App">
            <nav className="App-nav"/>
            <Grid />
        </div>
    );
}

const Grid = () => {
    return (
        <div className="Grid">
            <Cell/><Cell/><Cell/><Cell/>
            <Cell/><Cell/><Cell/><Cell/>
            <Cell/><Cell/><Cell/><Cell/>
            <Cell/><Cell/><Cell/><Cell/>
        </div>
    );
}

function letter() {
    const alphabet = "AABCDEEFGHIIJKLMNOOPQRSTUUVWXYZ"
    return alphabet[Math.floor(Math.random() * alphabet.length)]
}

const Cell = () => {
    return (
        <div className="Cell" role="cell">
            {letter()}
        </div>
    );
}

export default App;
